import React from 'react'
import { Link } from 'gatsby'
import ProjectPreview from './projectPreview'

const PageBuilderFeaturedProjectsGrid = ({ block, raw }) => {
  const { featuredProjects } = raw

  return (
    <section className="section is-tight-mobile featured-projects-grid">
      <div className="section__header is-lined-top">
        <div>Ausgewählte Projekte</div>
        <Link to="/projekte/">Alle Projekte →</Link>
      </div>
      <div className="columns is-multiline">
        {featuredProjects &&
          featuredProjects.map(featuredProject => {
            const project = featuredProject.projectLink
            return (
              <div
                className={`column featured-projects-grid__project ${featuredProject.columnSize}`}
                key={featuredProject.id}
              >
                <ProjectPreview
                  {...project}
                  columnSize={featuredProject.columnSize}
                  inFeaturedProjectsGrid={true}
                />
              </div>
            )
          })}
      </div>
    </section>
  )
}

export default PageBuilderFeaturedProjectsGrid
