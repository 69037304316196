import React from 'react'
import BlockText from './block-text'
import LinksDownloads from './linksDowloads'
import AnimateHeight from 'react-animate-height'
import MenuClose from '../images/icons/menu-close.svg'
import classNames from 'classnames'

class CollapsibleText extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      height: 0,
    }
  }

  toggleHeight = () => {
    const { height } = this.state

    this.setState({
      height: height === 0 ? 'auto' : 0,
    })
  }

  render() {
    const { height } = this.state
    const { title, date, body, linksDownloads, index } = this.props
    const iconClasses = classNames({
      'is-closed': height === 0,
    })

    return (
      <div className={`collapsible-text is-size-3 is-size-4-mobile ${date ? 'has-date' : ''}`}>
        <div className="collapsible-text__header">
          <div
            aria-expanded={height !== 0}
            aria-controls={`collapsible-text-${index}`}
            onClick={this.toggleHeight}
          >
            <div className="collapsible-text__toggle-icon">
              <MenuClose className={iconClasses} />
            </div>
            <div className="collapsible-text__title-date">
              {date && (
                <div className="collapsible-text__date">
                  <span>{date}</span>
                </div>
              )}
              <div className="collapsible-text__title">
                <span>{title}</span>
              </div>
            </div>
          </div>
        </div>
        <AnimateHeight
          className="collapsible-text__content"
          id={`collapsible-text-${index}`}
          duration={500}
          height={height}
        >
          {body && body.map(textBlock => <BlockText blocks={textBlock} key={textBlock._key} />)}
          {linksDownloads && <LinksDownloads linksDownloads={linksDownloads} />}
        </AnimateHeight>
      </div>
    )
  }
}

export default CollapsibleText
