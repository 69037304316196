import React from 'react'
import BlockText from './block-text'
import { buildImageObj, isBrowser, isRetina } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import LazyBackground from '../components/lazyBackground'
import _debounce from 'lodash.debounce'

class PageBuilderHero extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      imgWidth: 2400,
    }
  }

  onResize = () => {
    const imgWidth = this.getImgWidth()
    this.setState({
      imgWidth: imgWidth,
    })
  }

  getImgWidth = () => {
    const { isFullWidthImage } = this.props.raw
    let imgWidth
    if (isBrowser()) {
      const windowWidth = window.innerWidth
      if (!isFullWidthImage) {
        if (windowWidth <= 1025) {
          imgWidth = windowWidth
        } else {
          imgWidth = 510
        }
      } else {
        imgWidth = windowWidth
      }
      if (isRetina()) {
        imgWidth = imgWidth * 2
      }
      return Math.floor(imgWidth)
    }
  }

  render() {
    const { isFullWidthImage, image, headlineText, introText } = this.props.raw
    const imgWidth = this.state.imgWidth
    const imgUrl =
      image &&
      imageUrlFor(buildImageObj(image))
        .width(imgWidth)
        .url()
    const imgPlaceholderUrl =
      image &&
      imageUrlFor(buildImageObj(image))
        .width(imgWidth)
        .quality(40)
        .blur(80)
        .url()

    return (
      <div>
        <section
          className={`section is-tight-mobile hero is-fullheight ${
            isFullWidthImage ? 'image-fullwidth' : ''
          } ${!image ? 'no-image' : ''}`}
        >
          {isFullWidthImage ? (
            <div className="hero__inner">
              {imgUrl && imgPlaceholderUrl && (
                <div className="hero__image-container">
                  <div className="load-blur-container">
                    <LazyBackground
                      src={imgUrl}
                      placeholder={imgPlaceholderUrl}
                      classes="hero__bg-image"
                      alt={image.alt ? image.alt : ''}
                    />
                  </div>
                </div>
              )}
              <div className="hero__text">
                <div className="hero__title">
                  <h1 className="is-size-1 is-size-2-mobile">{headlineText}</h1>
                </div>
                <div className="hero__intro-text is-size-3 is-size-4-mobile">
                  {introText.map((textBlock, i) => (
                    <BlockText blocks={textBlock} key={i} />
                  ))}
                </div>
              </div>
            </div>
          ) : (
            <div className="hero__inner">
              <div className="hero__title">
                <h1 className="is-size-1 is-size-2-mobile">{headlineText}</h1>
              </div>
              {imgUrl && imgPlaceholderUrl && (
                <div className="hero__image-container">
                  <div className="load-blur-container">
                    <LazyBackground
                      src={imgUrl}
                      placeholder={imgPlaceholderUrl}
                      classes="hero__bg-image hero__bg-image--small"
                      alt={image.alt ? image.alt : ''}
                    />
                  </div>
                </div>
              )}
              <div className="hero__intro-text is-size-3 is-size-4-mobile">
                {introText &&
                  introText.map((textBlock, i) => <BlockText blocks={textBlock} key={i} />)}
              </div>
            </div>
          )}
        </section>
      </div>
    )
  }
  componentDidMount() {
    if (typeof window === 'undefined') {
      return
    }
    this.onResize()
    window.addEventListener(
      'resize',
      _debounce(() => {
        this.onResize()
      }, 1000)
    )
  }
  componentWillUnmount() {
    window.removeEventListener(
      'resize',
      _debounce(() => {
        this.onResize()
      }, 1000)
    )
  }
}

export default PageBuilderHero
