import React from 'react'
import BlockText from './block-text'
import classNames from 'classnames'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import { Fade } from 'react-awesome-reveal'
import LinksDownloads from './linksDowloads'

const PageBuilderContact = ({ block, raw, index }) => {
  const {
    title,
    headlineText,
    text,
    format,
    teamMember,
    buttonMailto,
    buttonText,
    linksDownloads,
    customClasses,
  } = raw
  const classes = classNames({
    'is-size-small': format === 'small',
    'no-buttons': !linksDownloads,
    [customClasses ? customClasses : '']: true,
  })

  const imgUrl =
    teamMember &&
    teamMember._rawImage &&
    imageUrlFor(buildImageObj(teamMember._rawImage))
      .width(314)
      .height(380)
      .url()

  const getSectionHeader = () => {
    if (title) {
      return <div>{title}</div>
    } else if (format === 'large') {
      return <div>Kontakt</div>
    } else return <></>
  }

  return (
    <section className={`section is-tight-mobile contact ${classes}`}>
      <div className="section__header is-lined-top">{getSectionHeader()}</div>
      <div className="contact__inner">
        {headlineText && (
          <div className="contact__headline is-size-1 is-size-2-mobile">
            {headlineText.map((textBlock, i) => (
              <Fade duration={400} triggerOnce key={i}>
                <BlockText blocks={textBlock} key={textBlock.id} />
              </Fade>
            ))}
          </div>
        )}
        {text && (
          <div className="contact__text is-size-3 is-size-4-mobile">
            {text.map((textBlock, i) => (
              <Fade duration={400} triggerOnce key={i}>
                <BlockText blocks={textBlock} key={textBlock.id} />
              </Fade>
            ))}
          </div>
        )}
        {linksDownloads && (
          <div className="contact__links-downloads">
            <LinksDownloads linksDownloads={linksDownloads} />
          </div>
        )}
        {teamMember && (
          <Fade duration={400} triggerOnce>
            <div className="contact__team-member is-size-3 is-size-4-mobile">
              <div>
                <div
                  className="contact__team-member-bg-image"
                  style={{ backgroundImage: `url(${imgUrl})` }}
                />
              </div>
              <div>
                {teamMember.name}, {teamMember.position}
              </div>
              <a href={`tel:${teamMember.phoneNumber}`}>{teamMember.phoneNumber}</a>
              <a href={`mailto:${teamMember.email}`}>{teamMember.email}</a>
            </div>
          </Fade>
        )}
        {buttonMailto && buttonText && (
          <Fade duration={400} triggerOnce>
            <div className="contact__link">
              <a className="btn" href={`mailto:${buttonMailto}`}>
                {buttonText}
              </a>
            </div>
          </Fade>
        )}
      </div>
    </section>
  )
}

export default PageBuilderContact
