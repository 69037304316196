import React from 'react'
import PageBuilderContent from './pageBuilderContent'
import PageBuilderColumns from './pageBuilderColumns'
import PageBuilderHeroSlider from './pageBuilderHeroSlider'
import PageBuilderAboutUs from './pageBuilderAboutUs'
import PageBuilderCollapsibleTextList from './pageBuilderCollapsibleTextList'
import PageBuilderLinksDownloadads from './pageBuilderLinksDownloads'
import PageBuilderProjectsGrid from './pageBuilderProjectsGrid'
import PageBuilderFeauturedProjectsGrid from './pageBuilderFeaturedProjectsGrid'
import PageBuilderContact from './pageBuilderContact'
import PageBuilderHero from './pageBuilderHero'
import PageBuilderTextImageSliders from './pageBuilderTextImageSliders'
import PageBuilderTeamMemberGrid from './pageBuilderTeamMemberGrid'
import PageBuilderTextLinksHero from './pageBuilderTextLinksHero'
import PageBuilderProjectMap from './pageBuilderProjectMap'

function PageBuilder(props) {
  const { type, pageBuilder, _rawPageBuilder, searchData } = props

  // Load the right component, based on the _type from Sanity
  const Components = {
    pageBuilderContent: PageBuilderContent,
    pageBuilderColumns: PageBuilderColumns,
    pageBuilderHero: PageBuilderHero,
    pageBuilderHeroSlider: PageBuilderHeroSlider,
    pageBuilderAboutUs: PageBuilderAboutUs,
    pageBuilderCollapsibleTextList: PageBuilderCollapsibleTextList,
    pageBuilderLinksDownloads: PageBuilderLinksDownloadads,
    pageBuilderProjectsGrid: PageBuilderProjectsGrid,
    pageBuilderFeaturedProjectsGrid: PageBuilderFeauturedProjectsGrid,
    pageBuilderContact: PageBuilderContact,
    pageBuilderTextImageSliders: PageBuilderTextImageSliders,
    pageBuilderTeamMemberGrid: PageBuilderTeamMemberGrid,
    pageBuilderTextLinksHero: PageBuilderTextLinksHero,
    pageBuilderProjectMap: PageBuilderProjectMap,
  }

  // 'raw' content needs to be passed in for the PortableText Component
  return pageBuilder.map((block, index) => {
    if (Components[block._type]) {
      return React.createElement(Components[block._type], {
        key: block._key,
        block: block,
        type,
        raw: _rawPageBuilder[index],
        searchData,
      })
    }
  })
}

export default PageBuilder
