import React from 'react'
import BlockText from './block-text'

const PageBuilderColumns = ({ block, raw }) => {
  const { title, columns, customClasses, isTitleShown } = raw

  return (
    <section
      className={`section columns-container is-tight-mobile ${customClasses && customClasses}`}
    >
      {isTitleShown && title && (
        <div className="section__header section__header--contact">{title}</div>
      )}
      <div className="columns">
        {columns &&
          columns.map(column => {
            const textBlocks = column.body
            return (
              <div className="column" id={column.id} key={column.key}>
                <div className="column__inner is-size-4">
                  {textBlocks.map(textBlock => (
                    <BlockText blocks={textBlock} key={textBlock.key} />
                  ))}
                </div>
              </div>
            )
          })}
      </div>
    </section>
  )
}

export default PageBuilderColumns
