import React from 'react'
import { Link } from 'gatsby'
import LinksDownloads from '../components/linksDowloads'

const PageBuilderLinksDownloads = ({ block, raw, index }) => {
  const links = raw.links
  return (
    <section>
      <LinksDownloads linksDownloads={links} />
    </section>
  )
}

export default PageBuilderLinksDownloads
