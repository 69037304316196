import React from 'react'
import BlockText from './block-text'
import TeamMemberGridGoup from './teamMemberGridGroup'
import Sticky from 'react-stickynode'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import VisibilitySensor from 'react-visibility-sensor'
import classNames from 'classnames'
import { Fade } from 'react-awesome-reveal'
import { isBrowser } from '../lib/helpers'

class PageBuilderTeamMemberGrid extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      navUpdating: null,
      currentSubection: null,
    }
  }

  // isIntroIn = () => {
  //   const { headlineTextNavTitle } = this.props.raw
  //   const introEl = document.getElementById(headlineTextNavTitle.toLowerCase())
  //   if (introEl) {
  //     const introElTop = introEl.getBoundingClientRect().top
  //     if (introElTop > 0 && introElTop < 100) {
  //       if (this.state.currentSubection !== headlineTextNavTitle) {
  //         this.setState({
  //           currentSubection: headlineTextNavTitle,
  //         })
  //       }
  //     }
  //   }
  // }

  updateNav = title => {
    this.setState({ navUpdating: true })
    setTimeout(() => {
      this.setState({ currentSubection: title })
      this.setState({ navUpdating: false })
    }, 800)
  }

  onVisibilityChange = (isVisible, title) => {
    if (!this.state.navUpdating) {
      isVisible && this.setState({ currentSubection: title })
    }
  }

  render() {
    const { gridGroups, headlineText, headlineTextNavTitle } = this.props.raw

    return (
      <section className="section is-tight-mobile team-member-grid">
        <VisibilitySensor
          onChange={isVisible => this.onVisibilityChange(isVisible, headlineTextNavTitle)}
          scrollCheck
        >
          <div className="section__ruler" />
        </VisibilitySensor>

        <div className="team-member-grid__inner">
          <div className="team-member-grid__nav">
            <Sticky enabled={true} top={84} bottomBoundary="#content" activeClass="is-sticky">
              <div
                className={classNames('team-member-grid__nav-item', {
                  'is-in': headlineTextNavTitle === this.state.currentSubection,
                })}
              >
                <AnchorLink
                  offset={84}
                  href={`#${headlineTextNavTitle.toLowerCase()}`}
                  onClick={() => this.updateNav(headlineTextNavTitle)}
                >
                  {headlineTextNavTitle}
                </AnchorLink>
              </div>
              {gridGroups.map(item => (
                <div
                  className={classNames('team-member-grid__nav-item', {
                    'is-in': item.title === this.state.currentSubection,
                  })}
                  key={item._key}
                >
                  <AnchorLink
                    offset={49}
                    href={`#${item.title.toLowerCase()}`}
                    onClick={() => this.updateNav(item.title)}
                  >
                    {item.title}
                  </AnchorLink>
                </div>
              ))}
            </Sticky>
          </div>
          <div className="team-member-grid__content">
            {/* <VisibilitySensor
              onChange={isVisible => this.onVisibilityChange(isVisible, headlineTextNavTitle)}
              partialVisibility
              scrollCheck
            > */}
            <div
              className="team-member-grid__intro is-size-1 is-size-2-mobile is-lined-bottom"
              id={`${headlineTextNavTitle.toLowerCase()}`}
            >
              <div className="team-member-grid__mobile-nav-title is-intro is-size-7">
                {headlineTextNavTitle}
              </div>
              {headlineText &&
                headlineText.map(textBlock => (
                  <Fade duration={400} triggerOnce>
                    <BlockText blocks={textBlock} key={textBlock.id} />
                  </Fade>
                ))}
            </div>
            {/* </VisibilitySensor> */}
            {gridGroups &&
              gridGroups.map((item, i) => {
                const title = item.title
                return (
                  <VisibilitySensor
                    onChange={isVisible => this.onVisibilityChange(isVisible, title)}
                    partialVisibility
                    scrollCheck
                    intervalCheck
                    intervalDelay={50}
                    minTopValue={300}
                  >
                    <TeamMemberGridGoup group={item} key={item.id} />
                  </VisibilitySensor>
                )
              })}
          </div>
        </div>
      </section>
    )
  }
  componentDidMount() {
    const { headlineTextNavTitle } = this.props.raw
    this.setState({
      currentSubection: headlineTextNavTitle,
    })
    if (isBrowser()) {
      const { hash } = location
      if (!hash) return
      window.requestAnimationFrame(() => {
        const anchor = document.querySelector(hash)
        const offset = anchor.getBoundingClientRect().top + window.scrollY - 49
        window.scroll({ top: offset, left: 0 })
      })
    }
  }
  componentDidUpdate() {
    // this.isIntroIn()
  }
}

export default PageBuilderTeamMemberGrid
