import React from 'react'
import 'react-slideshow-image/dist/styles.css'
import { Link } from 'gatsby'
import { Fade } from 'react-slideshow-image'
import { imageUrlFor } from '../lib/image-url'
import { buildImageObj, isBrowser, isRetina } from '../lib/helpers'
import Helmet from 'react-helmet'
import classNames from 'classnames'
import MouseTooltip from 'react-sticky-mouse-tooltip'
import { isMobile } from 'react-device-detect'
import { lsepFix } from '../lib/string-utils'
import _debounce from 'lodash.debounce'
import Vimeo from '@u-wave/react-vimeo'

class PageBuilderHeroSlider extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      // isSliderAuto: true,
      isHeroScrolled: false,
      isMouseOverImage: false,
      isMouseTooltipVisible: false,
      tooltipArrowDirection: null,
      height: 0,
      firstSlideUrl: null,
      firstSlideLoaded: false,
      imgWidth: 2400,
    }
    this.onMouseEnterSlide = this.onMouseEnterSlide.bind(this)
    this.onMouseOverSlide = this.onMouseOverSlide.bind(this)
    this.onMouseLeaveSlide = this.onMouseLeaveSlide.bind(this)
    this.onMouseEnterLink = this.onMouseEnterLink.bind(this)
    this.onMouseLeaveLink = this.onMouseLeaveLink.bind(this)
    this.slideRef = React.createRef()
    this.back = this.back.bind(this)
    this.next = this.next.bind(this)
  }

  onResize = () => {
    const imgWidth = this.getImgWidth()
    this.setState({
      imgWidth: imgWidth,
    })
  }

  getImgWidth = () => {
    let imgWidth
    if (isBrowser()) {
      const windowWidth = window.innerWidth
      imgWidth = windowWidth
      if (isRetina()) {
        // increase for hotspot on retina mobile
        if (windowWidth <= 1025) {
          imgWidth = imgWidth * 3.5
        }
        imgWidth = imgWidth * 2
      }
      return Math.floor(imgWidth)
    } else {
      return 2400
    }
  }

  updateDimensions = () => {
    this.setState({ height: window.innerHeight })
  }

  updateTooltipArrow(e) {
    let wM = window.innerWidth / 2
    let w = e.clientX
    let pos
    switch (true) {
      case w <= wM:
        pos = 'left'
        break
      case w > wM:
        pos = 'right'
        break
      default:
        pos = undefined
    }
    this.setState({ tooltipArrowDirection: pos })
  }

  onMouseEnterSlide(e) {
    this.updateTooltipArrow(e)
    this.setState({ isMouseTooltipVisible: true })
  }

  onMouseOverSlide(e) {
    this.updateTooltipArrow(e)
  }

  onMouseLeaveSlide() {
    this.setState({ isMouseTooltipVisible: false })
  }

  onMouseEnterLink(e) {
    this.setState({ isMouseTooltipVisible: false })
  }

  onMouseLeaveLink(e) {
    this.setState({ isMouseTooltipVisible: true })
  }

  isBottom(el) {
    return el.getBoundingClientRect().bottom <= window.innerHeight
  }

  isTop(el) {
    return el.getBoundingClientRect().top <= window.innerHeight
  }

  trackScrolling = () => {
    const navFadePoint = (
      Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0) * 0.35
    ).toFixed(3)
    const wrappedElement = document.getElementById('hero-slider')
    if (window.pageYOffset <= navFadePoint) {
      this.setState({ isHeroScrolled: false })
    } else if (this.isBottom(wrappedElement)) {
      this.setState({ isHeroScrolled: true })
    }
  }

  onSliderClick = () => {
    const tooltipArrowDirection = this.state.tooltipArrowDirection
    if (tooltipArrowDirection === 'right') {
      this.slideRef.current.goNext()
    } else if (tooltipArrowDirection === 'left') {
      this.slideRef.current.goBack()
    } else {
      this.slideRef.current.goNext()
    }
  }

  onSlideVideoLoad = i => {
    if (isBrowser()) {
      const el = document.querySelector(`[data-index="${i}"]`)
      if (el) {
        i === 0
          ? setTimeout(() => {
              el.classList.add('video-loaded')
            }, 2000)
          : el.classList.add('video-loaded')
      }
    }
  }

  back() {
    this.slideRef.current.goBack()
    // this.setState({
    //   isSliderAuto: false,
    // })
  }

  next() {
    this.slideRef.current.goNext()
    // this.setState({
    //   isSliderAuto: false,
    // })
  }

  render() {
    const slides = this.props.raw.landingSlides
    const imgWidth = this.getImgWidth()
    const { firstSlideUrl, firstSlideLoaded } = this.state
    const bodyClasses = classNames('nav-transparent', {
      'is-hero-scrolled': this.state.isHeroScrolled,
    })
    const height = isMobile ? `${this.state.height}px` : '100vh'
    const bgGradient = firstSlideLoaded
      ? 'linear-gradient(90deg, rgba(0,0,0,0.30015756302521013) 0%, rgba(0,0,0,0) 25%, rgba(0,0,0,0) 50%, rgba(0,0,0,0) 75%, rgba(0,0,0,0.30015756302521013) 100%)'
      : 'none'
    return (
      <>
        <Helmet>
          <body className={bodyClasses} />
        </Helmet>
        <div className={`hero-slider`} id="hero-slider">
          <div className="slide-container">
            <Fade
              ref={this.slideRef}
              arrows={false}
              autoplay={true}
              pauseOnHover={false}
              duration={4000}
              transitionDuration={400}
            >
              {slides &&
                slides.map((slide, i) => {
                  const imgUrl =
                    slide.asset &&
                    imageUrlFor(buildImageObj(slide))
                      .width(imgWidth)
                      .height(Math.floor((9 / 16) * imgWidth))
                      .url()
                  const infoText = slide.infoText && lsepFix(slide.infoText)
                  return (
                    <div className="each-fade">
                      <div
                        className={`hero-slider__slide hero is-fullheight`}
                        style={{
                          backgroundImage: `
                          ${bgGradient}, url(${i === 0 ? firstSlideUrl : imgUrl})`,
                          minHeight: height,
                          height: height,
                        }}
                        onClick={this.onSliderClick}
                        onMouseEnter={this.onMouseEnterSlide}
                        onMouseMove={this.onMouseOverSlide}
                        onMouseLeave={this.onMouseLeaveSlide}
                      >
                        {slide.videoUrl && (
                          <div className="hero-slider__video">
                            <Vimeo
                              video={slide.videoUrl}
                              autoplay
                              background
                              muted
                              loop
                              onReady={this.onSlideVideoLoad(i)}
                            />
                          </div>
                        )}
                        <div className="hero-slider__title hero-body">
                          <div className="container">
                            <h1 className="is-size-1 is-size-2-mobile">{slide.title}</h1>
                          </div>
                        </div>
                        <div className="hero-slider__info hero-foot">
                          <div className="container">
                            {infoText && <p>{infoText}</p>}
                            {slide.internalLink && !slide.internalProjectLink && (
                              <Link
                                className="btn is-transparent"
                                to={`/${slide.internalLink._rawSlug.current}/`}
                                onMouseEnter={this.onMouseEnterLink}
                                onMouseLeave={this.onMouseLeaveLink}
                              >
                                Mehr erfahren
                              </Link>
                            )}
                            {slide.internalProjectLink && !slide.internalLink && (
                              <Link
                                className="btn is-transparent"
                                to={`projekte/${slide.internalProjectLink._rawSlug.current}/`}
                                onMouseEnter={this.onMouseEnterLink}
                                onMouseLeave={this.onMouseLeaveLink}
                              >
                                Mehr erfahren
                              </Link>
                            )}
                          </div>
                        </div>
                        <MouseTooltip visible={this.state.isMouseTooltipVisible}>
                          <span
                            className={`slider-mouse-tooltip is-${this.state.tooltipArrowDirection}`}
                          >
                            →
                          </span>
                        </MouseTooltip>
                      </div>
                    </div>
                  )
                })}
            </Fade>
          </div>
        </div>
      </>
    )
  }
  componentDidMount() {
    if (typeof window === 'undefined') {
      return
    }
    const slides = this.props.raw.landingSlides
    const imgWidth = this.getImgWidth() || 2400
    if (slides) {
      const firstSlideUrl = imageUrlFor(buildImageObj(slides[0]))
        .width(imgWidth)
        .height(Math.floor((9 / 16) * imgWidth))
        .url()
      const firstSlidePlaceholderUrl = imageUrlFor(buildImageObj(slides[0]))
        .width(imgWidth)
        .height(Math.floor((9 / 16) * imgWidth))
        .quality(20)
        .blur(80)
        .url()
      this.setState({
        firstSlideUrl: firstSlidePlaceholderUrl,
      })
      const imageLoader = new Image()
      imageLoader.src = firstSlideUrl
      imageLoader.onload = () => {
        this.setState({
          firstSlideUrl: firstSlideUrl,
          firstSlideLoaded: true,
        })
        // init auto slider
        // setInterval(() => {
        //   this.state.isSliderAuto && this.next()
        // }, 4000)
      }
    }
    this.updateDimensions()
    document.addEventListener('scroll', this.trackScrolling)
    window.addEventListener(
      'resize',
      _debounce(() => {
        this.onResize()
      }, 1000)
    )
    // window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount() {
    document.removeEventListener('scroll', this.trackScrolling)
    window.removeEventListener('resize', this.updateDimensions)
    window.removeEventListener(
      'resize',
      _debounce(() => {
        this.onResize()
      }, 1000)
    )
  }
}

export default PageBuilderHeroSlider
