import React from 'react'
import CollapsibleText from './collapsibleText'

class PageBuilderCollapsibleTextList extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { title, mobileTitle, isTitleShown, isLinedTop, collabsibleTextItems } = this.props.raw

    return (
      <section className={`section collapsible-text-list is-tight-mobile`}>
        {isTitleShown && title && (
          <div
            className={`section__header section__header--desktop ${
              isLinedTop ? 'is-lined-top' : ''
            }`}
          >
            <span>{title}</span>
          </div>
        )}
        {isTitleShown && title && (
          <div
            className={`section__header section__header--mobile ${
              isLinedTop ? 'is-lined-top' : ''
            }`}
          >
            {mobileTitle ? mobileTitle : title}
          </div>
        )}
        {collabsibleTextItems.map((item, i) => (
          <CollapsibleText {...item} index={i + 1} key={i} />
        ))}
      </section>
    )
  }
}

export default PageBuilderCollapsibleTextList
