import React from 'react'
import LinksDownloads from './linksDowloads'
import PortableText from './portableText'
import { lsepFix } from '../lib/string-utils'

const PageBuilderTextLinksHero = ({ block, raw, index }) => {
  const { title, text, linksDownloads } = raw
  const _title = title && lsepFix(title)

  return (
    <section className="section hero text-links-hero is-tight-mobile">
      <div className="hero-body text-links-hero__inner">
        <div className="text-links-hero__title is-size-1 is-size-2-mobile">{_title}</div>
        <LinksDownloads linksDownloads={linksDownloads} />
        <div className="is-size-3 is-size-4-mobile">
          <PortableText blocks={text} />
        </div>
      </div>
    </section>
  )
}

export default PageBuilderTextLinksHero
