import React from 'react'
import TeamMemberGridProfile from './teamMemberGridProfile'
import BlockText from './block-text'
import { Fade } from 'react-awesome-reveal'

const TeamMemberGridGoup = props => {
  const { title, text, teamMembers } = props.group

  return (
    <div className="team-member-grid-group" id={`${title.toLowerCase()}`}>
      <div className="team-member-grid__mobile-nav-title is-size-7">{title}</div>
      <div className="team-member-grid-group__text is-size-3 is-size-4-mobile">
        {text &&
          text.map(textBlock => (
            <Fade duration={400} triggerOnce key={textBlock.id}>
              <BlockText blocks={textBlock} />
            </Fade>
          ))}
      </div>
      <Fade duration={400} triggerOnce>
        <div className="team-member-grid-group__profiles columns is-marginless is-flex is-multiline">
          {teamMembers &&
            teamMembers.map(teamMember => (
              <TeamMemberGridProfile teamMember={teamMember} key={teamMember.id} />
            ))}
        </div>
      </Fade>
    </div>
  )
}

export default TeamMemberGridGoup
