import React from 'react'
import BerlinBrandenburg from '../images/react-svg-map/berlin-brandenburg/regions-only'
import { SVGMap } from 'react-svg-map'
import { getLocationId, getLocationName } from '../lib/react-svg-map-utils'
import 'react-svg-map/lib/index.css'
import BlockText from './block-text'
import { Link } from 'gatsby'
import { navigate } from 'gatsby'

class PageBuilderProjectMap extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      pointedPath: null,
      focusedPath: null,
      clickedPath: null,
    }
    this.handlePathMouseOver = this.handlePathMouseOver.bind(this)
    this.handlePathMouseOut = this.handlePathMouseOut.bind(this)
    this.handlePathClick = this.handlePathClick.bind(this)
    this.handlePathFocus = this.handlePathFocus.bind(this)
    this.handlePathBlur = this.handlePathBlur.bind(this)
  }

  handlePathMouseOver = e => {
    const pointedPath = getLocationName(e)
    this.setState({ pointedPath: pointedPath })
  }

  handlePathMouseOut = e => {
    this.setState({ pointedPath: null })
  }

  handlePathClick = e => {
    const clickedPathId = getLocationId(e)
    navigate(`/projekte/#${clickedPathId}`)
  }

  handlePathFocus = e => {
    const focusedPath = getLocationName(e)
    this.setState({ focusedPath: focusedPath })
  }

  handlePathBlur() {
    this.setState({ focusedPath: null })
  }

  render() {
    const { text, linkText, internalLink } = this.props.raw
    return (
      <section className="section is-tight-mobile project-map">
        <div className="columns is-gapless">
          <div className="column is-two-thirds project-map__content is-size-3 is-size-4-mobile">
            {text &&
              text.map((textBlock, i) => <BlockText blocks={textBlock} key={`text-block--${i}`} />)}
            {linkText && internalLink && (
              <div className="project-map__link">
                <Link to={`/${internalLink._rawSlug.current}/`} className="btn">
                  {linkText}
                </Link>
              </div>
            )}
          </div>
          <div className="column is-one-third project-map__map">
            <div>
              <div className="project-map__hover-info">
                <span>
                  {this.state.pointedPath
                    ? `Berlin-Brandenburg, ${this.state.pointedPath}`
                    : `Berlin-Brandenburg`}
                </span>
              </div>
              <div className="project-map__svg">
                <SVGMap
                  map={BerlinBrandenburg}
                  type="link"
                  locationClassName="svg-map__path is-link"
                  onLocationMouseOver={this.handlePathMouseOver}
                  onLocationMouseOut={this.handlePathMouseOut}
                  onLocationClick={this.handlePathClick}
                  onLocationFocus={this.handlePathFocus}
                  onLocationBlur={this.handlePathBlur}
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default PageBuilderProjectMap
