import React from 'react'
import TextImageSlider from './textImageSlider'
import Sticky from 'react-stickynode'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import VisibilitySensor from 'react-visibility-sensor'
import classNames from 'classnames'

class PageBuilderTextImageSliders extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      currentSubection: null,
    }
  }

  updateNav = title => {
    this.setState({ navUpdating: true })
    setTimeout(() => {
      this.setState({ currentSubection: title })
      this.setState({ navUpdating: false })
    }, 800)
  }

  onVisibilityChange = (isVisible, title) => {
    if (!this.state.navUpdating) {
      isVisible && this.setState({ currentSubection: title })
    }
  }

  render() {
    const { textImageSliders } = this.props.raw

    return (
      <section className="section is-tight-mobile text-image-sliders">
        <div className="section__ruler" />
        <div className="text-image-sliders__inner">
          <div className="text-image-sliders__nav">
            <Sticky enabled={true} top={84} bottomBoundary="#content" activeClass="is-sticky">
              {textImageSliders.map((item, i) => (
                <div
                  className={classNames('text-image-sliders__nav-item', {
                    'is-in': item.title === this.state.currentSubection,
                  })}
                  key={item._key}
                >
                  <AnchorLink
                    offset={i === 0 ? 84 : 49}
                    href={`#${item.title.toLowerCase()}`}
                    onClick={() => this.updateNav(item.title)}
                  >
                    {item.title}
                  </AnchorLink>
                </div>
              ))}
            </Sticky>
          </div>
          <div className="text-image-sliders__content" id="content">
            {textImageSliders.map((item, i) => {
              const title = item.title
              return (
                <VisibilitySensor
                  onChange={isVisible => this.onVisibilityChange(isVisible, title)}
                  scrollCheck
                  intervalCheck
                  intervalDelay={50}
                  partialVisibility
                  minTopValue={300}
                  key={i}
                >
                  <TextImageSlider textImageSlider={item} />
                </VisibilitySensor>
              )
            })}
          </div>
        </div>
      </section>
    )
  }
}

export default PageBuilderTextImageSliders
