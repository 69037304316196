import React from 'react'
import PortableText from './portableText'
import LinksDownloads from './linksDowloads'

const PageBuilderContent = ({ block, raw, index }) => {
  const { title, text, linksDownloads, customClasses } = raw

  return (
    <section className={`section ${customClasses}`}>
      <div className="section__header">{title && <div>{title}</div>}</div>
      <PortableText blocks={text} />
      <LinksDownloads linksDownloads={linksDownloads} />
    </section>
  )
}

export default PageBuilderContent
