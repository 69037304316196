import React from 'react'
import { Link } from 'gatsby'
import BlockText from './block-text'
import { cn, buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'
import { Fade } from 'react-awesome-reveal'

const PageBuilderAboutUs = ({ block, raw, index }) => {
  const { text, image } = raw
  const imgUrl =
    image &&
    imageUrlFor(buildImageObj(image))
      .auto('format')
      .url()

  const renderAboutUsInfo = () => {
    return (
      <div className="about-us__info">
        <div className="about-us__copy">
          {text && text.map(textBlock => <BlockText blocks={textBlock} key={textBlock.id} />)}
        </div>
        <div className="about-us__link">
          <Link className="btn" to="/ueber-uns/">
            Mehr über uns erfahren
          </Link>
        </div>
      </div>
    )
  }

  const renderAboutUsImage = () => {
    return (
      <div className="about-us__image">
        <img src={imgUrl} alt={image.alt ? image.alt : ''} />
      </div>
    )
  }

  return (
    <Fade duration={400} triggerOnce>
      <section className="section is-tight-mobile about-us">
        <div className="section__header is-lined-top">
          <div>Über uns</div>
          <Link to="/ueber-uns/">Mehr erfahren →</Link>
        </div>
        <div className="columns about-us__content">
          <div className="column is-flex">{renderAboutUsImage()}</div>
          <div className="column is-flex">{renderAboutUsInfo()}</div>
        </div>
      </section>
    </Fade>
  )
}
export default PageBuilderAboutUs
