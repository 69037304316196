import React from 'react'
import ProjectPreview from './projectPreview'
import EllipseTransparent from '../images/icons/ellipse-transparent.svg'
import EllipseTransparentDashed from '../images/icons/ellipse-transparent-dashed.svg'
import EllipseHalf from '../images/icons/ellipse-half.svg'
import EllipseFill from '../images/icons/ellipse-fill.svg'
import EllipseFillLine from '../images/icons/ellipse-fill-line.svg'
import EllipseFillTriangle from '../images/icons/ellipse-fill-triangle.svg'
import classNames from 'classnames'
import BerlinBrandenburg from '../images/react-svg-map/berlin-brandenburg/regions-only'
import MainContext from './MainContext'
import { Fade } from 'react-awesome-reveal'
import { getFilteredProjects, isBrowser, isTopOut } from '../lib/helpers'
import AnchorLink from 'react-anchor-link-smooth-scroll'

class PageBuilderProjectsGrid extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isFilterOpen: true,
      isFiltering: false,
      isFilterMap: false,
      selectedCategory: null,
      selectedStatus: null,
      selectedArea: null,
      initialStickyOffset: 100,
    }
    this.toggleSelectedCategory = this.toggleSelectedCategory.bind(this)
    this.toggleSelectedStatus = this.toggleSelectedStatus.bind(this)
    this.toggleSelectedArea = this.toggleSelectedArea.bind(this)
    this.containerRef = React.createRef()
    // this.toggleFilter = this.toggleFilter.bind(this)
  }

  initFirstArea = () => {
    const mapClickedAreaId = location.href.split('#').pop()
    const projects = this.props.raw.projects
    const filterableAreas = [...new Set(projects.map(project => project._rawArea.title))]
    BerlinBrandenburg.locations.map(item => {
      if (item.id === mapClickedAreaId) {
        if (filterableAreas.includes(item.name)) {
          this.setState({ selectedArea: item.name, isFiltering: true })
        }
      }
    })
  }

  toggleStickyGroupTitles() {
    const els = this.containerRef.current.querySelectorAll(`[data-ref="status-group"]`)
    const filterOpenClass = document.documentElement.classList.contains('is-projects-filter-open')
    if (filterOpenClass) {
      this.state.initialStickyOffset !== 50 && this.setState({ initialStickyOffset: 50 })
    } else {
      this.state.initialStickyOffset !== 100 && this.setState({ initialStickyOffset: 100 })
    }

    els.forEach((el, i) => {
      const scrolledOffset = this.state.initialStickyOffset
      const stickyEl = el.querySelector(`[data-ref="sticky"]`)
      if (i > 0) {
        stickyEl.style.top = `${this.state.initialStickyOffset}px`
      } else {
        stickyEl.style.top = `${this.state.initialStickyOffset + 13}px`
      }
      if (isTopOut(el, scrolledOffset)) {
        el.classList.add('is-scrolled')
      } else {
        el.classList.remove('is-scrolled')
      }
    })
  }

  onScroll = () => {
    this.toggleStickyGroupTitles()
  }

  toggleSelectedCategory(e) {
    const clickedCategory = e.target.value
    const { selectedCategory } = this.state
    if (clickedCategory !== selectedCategory) {
      this.setState({
        selectedCategory: clickedCategory,
        isFiltering: true,
      })
    } else {
      this.setState({
        selectedCategory: null,
      })
    }
    this.onFilterToggle()
  }

  toggleSelectedStatus(e) {
    const clickedStatus = e.target.value
    const { selectedStatus } = this.state
    if (clickedStatus !== selectedStatus) {
      this.setState({
        selectedStatus: clickedStatus,
        isFiltering: true,
      })
    } else {
      this.setState({
        selectedStatus: null,
      })
    }
    this.onFilterToggle()
  }

  toggleSelectedArea(e) {
    const clickedArea = e.target.value
    const { selectedArea } = this.state
    if (clickedArea !== selectedArea) {
      this.setState({
        selectedArea: clickedArea,
        isFiltering: true,
      })
    } else {
      this.setState({
        selectedArea: null,
      })
    }
    this.onFilterToggle()
  }

  setFilterNavString = (status, category, area) => {
    const filterNavString = `${status} | ${category} | ${area}`
    this.setState({ filterNavString: filterNavString })
  }

  onFilterToggle() {
    const { selectedStatus, selectedCategory, selectedArea } = this.state
    this.setFilterNavString(selectedStatus, selectedCategory, selectedArea)
  }

  renderStatusIcon(title) {
    let icon = null
    switch (title) {
      case 'In Planung':
        icon = <EllipseTransparentDashed />
        break
      case 'Im Bau':
        icon = <EllipseHalf />
        break
      case 'Abgeschlossen':
        icon = <EllipseFill />
        break
      case 'Im Bestand':
        icon = <EllipseFillLine />
        break
      case 'Im Vertrieb':
        icon = <EllipseFillTriangle />
        break
    }
    return icon
  }

  renderProjectGroups(projects) {
    const { statuses } = this.props.searchData
    return (
      <div ref={this.containerRef}>
        {statuses.map((status, i) => {
          const groupedProjects = projects.filter(project => {
            return project._rawStatus.title === status.title
          })
          if (groupedProjects.length) {
            const groupId = status.title.replace(/\s+/g, '-').toLowerCase()
            const filterOpenClass = document.documentElement.classList.contains(
              'is-projects-filter-open'
            )
            const initialOffset = filterOpenClass ? 50 : 100
            const indexOffset = i * 50
            const scrolledOffset = this.state.initialStickyOffset + indexOffset
            // const anchorOffset = i > 0 ? scrolledOffset : this.state.initialStickyOffset
            const anchorOffset = this.state.initialStickyOffset - 1
            return (
              <div
                className="projects-grid__group"
                data-ref="status-group"
                data-index={i}
                id={`${groupId}`}
              >
                <AnchorLink offset={anchorOffset} href={`#${groupId}`}>
                  <div className="projects-grid__group-title">
                    <span>
                      <span>{this.renderStatusIcon(status.title)}</span>&nbsp;&nbsp;
                      <span className="projects-grid__group-title-text">{status.title}</span>
                    </span>
                  </div>
                </AnchorLink>
                <AnchorLink offset={anchorOffset} href={`#${groupId}`}>
                  <div
                    className="projects-grid__group-title projects-grid__group-title--sticky"
                    data-ref="sticky"
                  >
                    <span>
                      <span>{this.renderStatusIcon(status.title)}</span>&nbsp;&nbsp;
                      <span className="projects-grid__group-title-text">{status.title}</span>
                    </span>
                  </div>
                </AnchorLink>
                <ul className="columns is-marginless is-multiline">
                  {groupedProjects.map((project, i) => {
                    if (project._rawStatus.title === status.title) {
                      return (
                        <Fade duration={400} triggerOnce cascade key={project.id}>
                          <li
                            className="column is-one-third-desktop is-half-tablet is-flex"
                            key={project.id}
                          >
                            <ProjectPreview {...project} useRawExternalLinkFile loadOrder={i} />
                          </li>
                        </Fade>
                      )
                    }
                  })}
                </ul>
              </div>
            )
          }
        })}
      </div>
    )
  }

  render() {
    const props = this.props
    const projects = this.props.raw.projects.filter(project => project.visible)
    const { categories, statuses, areas } = props.searchData
    const { selectedStatus, selectedCategory, selectedArea, isFiltering, isFilterOpen } = this.state
    const gridClasses = classNames({
      'is-filter-open': isFilterOpen,
    })
    // filtering
    let filteredProjects = getFilteredProjects(
      projects,
      selectedStatus,
      selectedCategory,
      selectedArea
    )
    let filterableAreas = [...new Set(filteredProjects.map(project => project._rawArea.title))]
    let filterableCategories = [
      ...new Set(
        Array.prototype.concat.apply(
          [],
          filteredProjects.map(project => project._rawCategories.map(category => category.title))
        )
      ),
    ]
    let filterableStatuses = [...new Set(filteredProjects.map(project => project._rawStatus.title))]
    let noneFilterable =
      filterableAreas.length < 1 && filterableCategories.length < 1 && filterableStatuses < 1
    // sort categories in filter list
    const sortedCategories = categories.sort((a, b) => (a.filterOrder > b.filterOrder ? 1 : -1))
    const sortedStatuses = statuses.sort((a, b) => (a.filterOrder > b.filterOrder ? 1 : -1))
    const sortedAreas = areas.sort((a, b) => (a.filterOrder > b.filterOrder ? 1 : -1))

    return (
      <>
        <section className={`section is-tight-mobile projects-grid ${gridClasses}`}>
          <div className="projects-grid__filter">
            <div className="projects-grid__filter-inner">
              <div className="projects-grid__filter-group">
                <div className="projects-grid__filter-header">Orte zum:</div>
                <div className="projects-grid__filter-buttons">
                  {sortedCategories.map((category, i) => (
                    <button
                      className={`${category.title === selectedCategory ? 'is-selected' : ''}
                    ${!filterableCategories.includes(category.title) ? 'not-filterable' : ''}
                    `}
                      onClick={this.toggleSelectedCategory}
                      key={i}
                      value={category.title}
                    >
                      <EllipseTransparent />
                      {category.title}
                    </button>
                  ))}
                </div>
              </div>
              <div className="projects-grid__filter-group">
                <div className="projects-grid__filter-header">Status:</div>
                <div className="projects-grid__filter-buttons">
                  {sortedStatuses.map((status, i) => (
                    <button
                      className={`${status.title === selectedStatus ? 'is-selected' : ''}
                    ${!filterableStatuses.includes(status.title) ? 'not-filterable' : ''}
                    `}
                      onClick={this.toggleSelectedStatus}
                      key={i}
                      value={status.title}
                    >
                      <EllipseTransparent />
                      {status.title}
                    </button>
                  ))}
                </div>
              </div>
              <div className="projects-grid__filter-group">
                <div className="projects-grid__filter-header">Projekte in:</div>
                <div className="projects-grid__filter-buttons">
                  {sortedAreas.map((area, i) => (
                    <button
                      className={`${area.title === selectedArea ? 'is-selected' : ''}
                    ${!filterableAreas.includes(area.title) ? 'not-filterable' : ''}
                    `}
                      data-filter-type="area"
                      onClick={this.toggleSelectedArea}
                      key={i}
                      value={area.title}
                    >
                      <EllipseTransparent />
                      {area.title}
                    </button>
                  ))}
                </div>
              </div>
            </div>
          </div>
          {isBrowser() && (
            <div className="projects-grid__projects">
              {isFiltering
                ? filteredProjects && this.renderProjectGroups(filteredProjects)
                : this.renderProjectGroups(projects)}
            </div>
          )}
          {props.browseMoreHref && (
            <div>
              <Link to={props.browseMoreHref}>Browse more</Link>
            </div>
          )}
        </section>
      </>
    )
  }
  componentDidMount() {
    if (typeof window === 'undefined') {
      return
    }
    this.initFirstArea()
    document.addEventListener('scroll', this.onScroll)
    // this.getWindowWidth()
  }
  componentDidUpdate() {
    if (document.body.classList.contains('projects-filter-cleared')) {
      if (this.state.isFiltering !== false) {
        this.setState({
          isFiltering: false,
          selectedCategory: null,
          selectedStatus: null,
          selectedArea: null,
        })
        this.context.setFilterNavString('')
      }
    }
    // if (!document.documentElement.classList.contains('is-projects-filter-open')) {
    //   if (this.state.isFilterOpen !== false) {
    //     this.setState({
    //       isFilterOpen: false,
    //     })
    //   } else {
    //     if (this.state.isFilterOpen !== true)
    //       this.setState({
    //         isFilterOpen: true,
    //       })
    //   }
    // }
    const { selectedCategory, selectedStatus, selectedArea } = this.state
    const selectionArr = [selectedCategory, selectedStatus, selectedArea]
    const selectionStr = selectionArr.filter(item => item !== null).join(', ')
    const _selectionStr = selectionStr ? selectionStr : ''
    this.context.setFilterNavString(_selectionStr)
  }
  componentWillUnmount() {
    this.context.setFilterNavString('')
    document.removeEventListener('scroll', this.onScroll)
  }
}

PageBuilderProjectsGrid.contextType = MainContext

export default PageBuilderProjectsGrid
