import React from 'react'
// import BlockText from './block-text'
import { buildImageObj } from '../lib/helpers'
import { imageUrlFor } from '../lib/image-url'

const TeamMemberGridProfile = props => {
  const teamMember = props.teamMember
  // const textBlocks = teamMember.bio
  const imgUrl =
    teamMember._rawImage.asset &&
    imageUrlFor(buildImageObj(teamMember._rawImage))
      .width(320)
      .height(400)
      .url()

  return (
    <div className="team-member-grid-profile column is-one-quarter is-one-third-tablet is-half-mobile is-flex is-size-5">
      <div className="team-member-grid-profile__image">
        <div
          className="team-member-grid-profile__bg-image"
          style={{ backgroundImage: `url(${imgUrl})` }}
          role="img"
          aria-label={teamMember.name ? teamMember.name : ''}
        />
      </div>
      <div className="team-member-grid-profile__name">
        {teamMember.name && <div>{teamMember.name}</div>}
        {teamMember.position && <div>{teamMember.position}</div>}
      </div>
      {/* <div className="team-member-grid-profile__bio">
        {textBlocks &&
          textBlocks.map(textBlock => <BlockText blocks={textBlock} key={textBlock.id} />)}
      </div> */}
      {/* <div className="team-member-grid-profile__link">
        <a className="btn" href={`mailto:${teamMember.email}`}>
          Kontaktieren
        </a>
      </div> */}
    </div>
  )
}

export default TeamMemberGridProfile
